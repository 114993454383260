

fun String.isANumber(): Boolean {
    this.forEach {
        if (!it.isDigit())
            return false
    }
    return true
}

fun String.takeIfNotBlank(): String? = takeIf { it.isNotBlank() }
fun Double.takeIfGreaterThanZero(): Double? = takeIf { it > 0.0 }
fun Double.equalsZero(): Boolean = this == 0.0
fun Double.greaterOrEqualToZero(): Boolean = this > 0.0 || equalsZero()
fun Double?.orZero(): Double = this ?: 0.0
fun Double.negate(): Double = -this
fun Any?.toStringOrNull(): String? = this?.let { it.toString() }
fun Any?.toStringOrEmpty(): String = this?.let { it.toString() } ?: ""
fun String.takeMaxLast(maxLength: Int): String = if (length > maxLength) takeLast(maxLength) else this