package ui.consumerInformation

import androidx.compose.foundation.layout.ColumnScope
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.lazy.items
import androidx.compose.foundation.lazy.rememberLazyListState
import androidx.compose.material.Divider
import androidx.compose.material.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import model.ConsumerInformation
import model.MerchantFullInformation
import ui.scrollbar.LazyColumnScrollbar

@Composable
fun ColumnScope.ConsumerStoreInformationDisplay(
    merchants: List<MerchantFullInformation>,
    merchantCardView: @Composable (MerchantFullInformation, (MerchantFullInformation) -> Unit) -> Unit,
    onMerchantClicked: (MerchantFullInformation) -> Unit,
    shouldShowScrollbar: Boolean,
) {
    Text(
        "My Stores",
        fontWeight = FontWeight.ExtraBold,
        modifier = Modifier.align(Alignment.Start).padding(start = 16.dp, top = 16.dp, bottom = 16.dp),
        fontSize = 20.sp
    )
    val lazyLisState = rememberLazyListState()

    LazyColumnScrollbar(
        listState = lazyLisState,
        padding = 4.dp,
        alwaysShowScrollBar = shouldShowScrollbar,
    ) {
        LazyColumn(
            state = lazyLisState,
            modifier = Modifier
        ) {
            items(merchants.filter { it.name?.isNotBlank() == true }) {
                merchantCardView(it, onMerchantClicked)
                Divider(
                    color = Color.White,
                    modifier = Modifier
                        .height(16.dp)
                )
            }
        }
    }
}