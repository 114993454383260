package model

import kotlinx.datetime.LocalDateTime
import kotlinx.serialization.Serializable

@Serializable
data class Consumer(
    val consumerId: Int? = -1,
    val namePrefix: String? = "",
    val nameFirst: String? = "",
    val nameMiddle: String? = "",
    val nameLast: String? = "",
    val nameSuffix: String? = "",
    val birthDate: LocalDateTime? = null,
)