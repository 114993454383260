package ui.login

import androidx.compose.foundation.Image
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.wrapContentSize
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.Text
import androidx.compose.material3.DropdownMenu
import androidx.compose.material3.DropdownMenuItem
import androidx.compose.material3.OutlinedButton
import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import kotlinx.coroutines.flow.StateFlow
import model.CountryCodeAndFlag
import org.jetbrains.compose.resources.painterResource


@Composable
fun CountryCodeDropDown(
    countryCodeStateFlow: StateFlow<Int>,
    onCountryCodeChanged: (Int) -> Unit,
) {
    var expanded by remember { mutableStateOf(false) }
    val items = CountryCodeAndFlag.entries
    val countryCode by countryCodeStateFlow.collectAsState()
    val selectedIndex = items.indexOf(CountryCodeAndFlag.fromCountryCode(countryCode))

    OutlinedButton(
        onClick = {
            expanded = true
        },
        modifier = Modifier.fillMaxHeight().padding(top = 8.dp),
        shape = RoundedCornerShape(0.dp),
    ) {
        Image(
            painter = painterResource(items[selectedIndex].flagImage),
            contentDescription = "",
            modifier = Modifier.size(24.dp),
        )
        Text(" +${items[selectedIndex].countryCode}")
    }

    DropdownMenu(
        expanded = expanded,
        onDismissRequest = { expanded = false },
        modifier = Modifier.wrapContentSize()
    ) {
        items.forEachIndexed { index, value ->
            DropdownMenuItem(text = {
                Row {
                    Image(
                        painter = painterResource(value.flagImage),
                        contentDescription = "",
                        modifier = Modifier.size(24.dp)
                    )
                    Text(" +${value.countryCode}")
                }
            }, onClick = {
                expanded = false
                onCountryCodeChanged(items[index].countryCode)
            })
        }
    }
}