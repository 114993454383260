package ui

import ConfigFile
import Flavor
import androidx.lifecycle.ViewModel
import androidx.lifecycle.viewModelScope
import globalCoroutineExceptionHandler
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.delay
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.asStateFlow
import kotlinx.coroutines.flow.filter
import kotlinx.coroutines.flow.filterNotNull
import kotlinx.coroutines.flow.take
import kotlinx.coroutines.flow.timeout
import kotlinx.coroutines.launch
import model.Card
import model.ConsumerInformation
import model.MerchantFullInformation
import model.NavigationState
import model.OrderPayment
import model.Repository
import persistence.TokenPersistence
import receipt.Receipt
import ui.snackbars.SnackBarState
import kotlin.time.Duration.Companion.minutes
import kotlin.time.Duration.Companion.seconds

class AppViewModel(
    private val repository: Repository,
    private val tokenPersistence: TokenPersistence,
    private val flavor: Flavor,
    val clientConfig: ConfigFile
) : ViewModel() {

    private val snackbarStateMutableStateFlow: MutableStateFlow<SnackBarState> = MutableStateFlow(SnackBarState.Nothing())
    val snackbarStateStateFlow = snackbarStateMutableStateFlow.asStateFlow()

    private val merchantInformationMutableStateFlow: MutableStateFlow<MerchantFullInformation?> = MutableStateFlow(null)
    val merchantInformationStateFlow = merchantInformationMutableStateFlow.asStateFlow()

    private val cardsMutableStateFlow: MutableStateFlow<List<Card?>?> = MutableStateFlow(null)
    val cardsStateFlow = cardsMutableStateFlow.asStateFlow()

    private val consumerInformationMutableStateFlow: MutableStateFlow<ConsumerInformation?> = MutableStateFlow(null)
    val consumerInformationStateFlow = consumerInformationMutableStateFlow.asStateFlow()

    private val paymentsMutableStateFlow: MutableStateFlow<PaymentState> = MutableStateFlow(PaymentState.Refreshing(emptyList()))
    val paymentsStateFlow = paymentsMutableStateFlow.asStateFlow()

    private val eReceiptMutableStateFlow: MutableStateFlow<Receipt?> = MutableStateFlow(null)
    val eReceiptStateFlow: StateFlow<Receipt?> = eReceiptMutableStateFlow.asStateFlow()

    private val navigationStateMutableStateFlow: MutableStateFlow<NavigationState> = MutableStateFlow(NavigationState.Blank())
    val navigationStateFlow = navigationStateMutableStateFlow.asStateFlow()

    private val backStackMutableStateFlow = MutableStateFlow<List<NavigationState>>(emptyList())
    val backStackStateFlow = backStackMutableStateFlow.asStateFlow()

    val clientName = flavor.clientName

    sealed class PaymentState(val payments: List<OrderPayment>) {
        class Payments(payments: List<OrderPayment>) : PaymentState(payments)
        class Refreshing(payments: List<OrderPayment>) : PaymentState(payments)
    }

    sealed class DialogState() {
        class Nothing() : DialogState()
        class LogOutConfirmation(val onConfirm: (Boolean) -> Unit) : DialogState()
    }

    private val dialogStateMutableStateFlow = MutableStateFlow<DialogState>(DialogState.Nothing())
    val dialogStateStateFlow = dialogStateMutableStateFlow

    fun setMerchant(merchant: MerchantFullInformation) {
        val lastSelectedMerchant = merchantInformationMutableStateFlow.value
        if (lastSelectedMerchant != merchant) {
            merchantInformationMutableStateFlow.value = merchant
            loadPaymentsAsync(merchant.consumerLoyaltyProgramGuid!!)
        } else {
            refreshPaymentsAsync()
        }
        navigate(NavigationState.MerchantInformation(merchant.consumerLoyaltyProgramGuid!!))
    }

    private fun loadPaymentsAsync(consumerLoyaltyProgramGuid: String) {
        paymentsMutableStateFlow.value = PaymentState.Refreshing(emptyList())
        viewModelScope.launch(globalCoroutineExceptionHandler + Dispatchers.Default) {
            paymentsMutableStateFlow.value = PaymentState.Payments(repository.getMerchantInformation(consumerLoyaltyProgramGuid).orderPayment)
        }
    }

    fun refreshPaymentsAsync() {
        paymentsMutableStateFlow.value = PaymentState.Refreshing(paymentsMutableStateFlow.value.payments)
        viewModelScope.launch(globalCoroutineExceptionHandler + Dispatchers.Default) {
            paymentsMutableStateFlow.value = PaymentState.Payments(repository.getMerchantInformation(merchantInformationMutableStateFlow.value!!.consumerLoyaltyProgramGuid!!).orderPayment)
        }
    }

    private fun navigate(navigationState: NavigationState) {
        when (navigationState) {
            is NavigationState.Blank -> Unit

            is NavigationState.Login -> {
                backStackMutableStateFlow.value = emptyList()
            }

            is NavigationState.LoginSubmitted -> Unit

            is NavigationState.Auth -> Unit

            is NavigationState.AuthFailed -> Unit

            is NavigationState.Exit -> Unit

            is NavigationState.ConsumerInformation -> {
                backStackMutableStateFlow.value = listOf(navigationState)
            }

            is NavigationState.MerchantInformation -> {
                val newBackStack = listOf(NavigationState.ConsumerInformation())
                backStackMutableStateFlow.value = newBackStack + navigationState
            }

            is NavigationState.TermsOfService -> {
                if (getToken() != null) {
                    val newBackStack = if (navigationStateFlow.value is NavigationState.EReceipt) {
                        backStackMutableStateFlow.value
                    } else {
                        if (consumerInformationStateFlow.value == null) {
                            viewModelScope.launch(globalCoroutineExceptionHandler + Dispatchers.Default) {
                                setConsumerInformationFromToken()
                            }
                        }
                        listOf(NavigationState.ConsumerInformation())
                    }
                    backStackMutableStateFlow.value = newBackStack + navigationState
                }
            }

            is NavigationState.PrivacyPolicy -> {
                if (getToken() != null) {
                    val newBackStack = if (navigationStateFlow.value is NavigationState.EReceipt) {
                        backStackMutableStateFlow.value
                    } else {
                        if (consumerInformationStateFlow.value == null) {
                            viewModelScope.launch(globalCoroutineExceptionHandler + Dispatchers.Default) {
                                setConsumerInformationFromToken()
                            }
                        }
                        listOf(NavigationState.ConsumerInformation())
                    }
                    backStackMutableStateFlow.value = newBackStack + navigationState
                }
            }

            is NavigationState.EReceipt -> {
                val newBackStack = if (getToken() == null) {
                    emptyList()
                } else if (navigationStateFlow.value is NavigationState.MerchantInformation) {
                    backStackStateFlow.value
                } else {
                    viewModelScope.launch(globalCoroutineExceptionHandler + Dispatchers.Default) {
                        if (consumerInformationStateFlow.value == null) {
                            launch { setConsumerInformationFromToken() }
                        }
                        eReceiptStateFlow
                            .filterNotNull()
                            .filter { it.orderPaymentGuid == navigationState.guid }
                            .take(1)
                            .timeout(1.minutes)
                            .collect { eReceipt ->
                                eReceipt.consumerLoyaltyProgramGuid?.let { consumerLoyaltyProgramGuid ->
                                    launch {
                                        consumerInformationMutableStateFlow
                                            .filterNotNull()
                                            .take(1)
                                            .timeout(1.minutes)
                                            .collect { consumerInformation ->
                                                val merchantInfo = consumerInformation.merchants.firstOrNull { it?.consumerLoyaltyProgramGuid == consumerLoyaltyProgramGuid }
                                                if (merchantInfo != null) {
                                                    merchantInformationMutableStateFlow.value = merchantInfo
                                                    launch { loadPaymentsAsync(consumerLoyaltyProgramGuid) }
                                                    if (navigationStateFlow.value == navigationState) {
                                                        val merchantInformation = NavigationState.MerchantInformation(consumerLoyaltyProgramGuid)
                                                        backStackMutableStateFlow.value = listOf(NavigationState.ConsumerInformation(), merchantInformation, navigationState)
                                                    }
                                                }
                                            }
                                    }
                                }
                            }
                    }
                    listOf(NavigationState.ConsumerInformation())
                }
                backStackMutableStateFlow.value = newBackStack + navigationState
            }
        }

        navigationStateMutableStateFlow.value = navigationState
    }

    fun displayConsumerInformation() {
        loadConsumerInformationAsync()
        navigate(NavigationState.ConsumerInformation())
    }

    private fun loadConsumerInformationAsync() {
        consumerInformationMutableStateFlow.value = null
        viewModelScope.launch(globalCoroutineExceptionHandler + Dispatchers.Default) {
            setConsumerInformationFromToken()
        }
    }

    private suspend fun setConsumerInformationFromToken() {
        val consumerInformation = repository.getConsumerInformation()
        if (consumerInformation != null) {
            consumerInformationMutableStateFlow.value = consumerInformation
            cardsMutableStateFlow.value = consumerInformationStateFlow.value?.cards ?: emptyList()
        } else {
            logOut()
        }
    }

    fun setEreceipt(orderPaymentGuid: String) {
        if (eReceiptStateFlow.value?.orderPaymentGuid != orderPaymentGuid) {
            loadEreceiptAsync(orderPaymentGuid)
        }
        navigate(NavigationState.EReceipt(orderPaymentGuid))
    }

    private fun loadEreceiptAsync(orderPaymentGuid: String) {
        eReceiptMutableStateFlow.value = null
        viewModelScope.launch(globalCoroutineExceptionHandler + Dispatchers.Default) {
            val eReceipt = repository.getEReceiptInformation(orderPaymentGuid)
            if (eReceipt.orderPaymentGuid.isNotBlank()) {
                eReceiptMutableStateFlow.value = eReceipt
            } else {
                snackbarStateMutableStateFlow.value = SnackBarState.InvalidEreceipt()
            }
        }
    }

    fun hashStateChangeEvent(newHash: String, locationListener: (String) -> Unit) {
        val navigationState = NavigationState.fromHash(newHash)
        if (navigationState == null) {
            if (backStackStateFlow.value.isEmpty()) {
                navigationStateMutableStateFlow.value = NavigationState.Login()
            }
            locationListener(navigationStateMutableStateFlow.value.asUrlString())
            return
        }

        val currentState = backStackStateFlow.value.lastOrNull()
        if (navigationState != currentState) {
            when (navigationState) {
                is NavigationState.Login -> {
                    if (getToken() != null) {
                        displayConsumerInformation()
                    } else {
                        navigate(NavigationState.Login())
                        locationListener(NavigationState.Login().asUrlString())
                    }
                }

                is NavigationState.Auth -> {
                    navigationStateMutableStateFlow.value = navigationState
                    authenticateGuid(navigationState.guid)
                }

                is NavigationState.ConsumerInformation -> {
                    if (getToken() != null) {
                        displayConsumerInformation()
                    } else {
                        navigate(NavigationState.Login())
                        locationListener(NavigationState.Login().asUrlString())
                    }
                }

                is NavigationState.MerchantInformation -> {
                    if (getToken() != null) {
                        viewModelScope.launch(globalCoroutineExceptionHandler + Dispatchers.Default) {
                            if (consumerInformationStateFlow.value == null) {
                                setConsumerInformationFromToken()
                            }
                            val consumerInformation = consumerInformationStateFlow.value!!
                            val merchant = consumerInformation.merchants.first { it!!.consumerLoyaltyProgramGuid == navigationState.guid }!!
                            merchantInformationMutableStateFlow.value = merchant
                            loadPaymentsAsync(merchant.consumerLoyaltyProgramGuid!!)
                        }
                        navigate(NavigationState.MerchantInformation(navigationState.guid))
                    } else {
                        navigate(NavigationState.Login())
                        locationListener(NavigationState.Login().asUrlString())
                    }
                }

                is NavigationState.EReceipt -> {
                    setEreceipt(navigationState.guid)
                }

                is NavigationState.TermsOfService -> {
                    setTermsOfService()
                }

                is NavigationState.PrivacyPolicy -> {
                    setPrivacyPolicy()
                }

                else -> Unit
            }
        }
    }

    private fun authenticateGuid(guid: String) {
        viewModelScope.launch(globalCoroutineExceptionHandler + Dispatchers.Default) {
            val consumerToken = repository.authenticateGuid(guid)
            if (consumerToken.isNotEmpty()) {
                tokenPersistence.setToken(consumerToken)
                displayConsumerInformation()
            } else {
                if (getToken() != null) {
                    navigationStateMutableStateFlow.value = NavigationState.ConsumerInformation()
                } else {
                    navigationStateMutableStateFlow.value = NavigationState.AuthFailed()
                    delay(5.seconds)
                    navigate(NavigationState.Login())
                }
            }
        }
    }

    fun popBackStack() {
        backStackStateFlow.value.let {
            if (it.size > 1) {
                navigate(it[it.size - 2])
            } else {
                navigate(NavigationState.Exit())
            }
        }
    }

    fun getToken(): String? {
        return tokenPersistence.getToken()
    }

    fun isTokenAvailable(): Boolean{
        return !tokenPersistence.getToken().isNullOrEmpty()
    }
    fun requestLogOut() {
        dialogStateMutableStateFlow.value = DialogState.LogOutConfirmation {
            dialogStateMutableStateFlow.value = DialogState.Nothing()
            if (it) {
                logOut()
            }
        }
    }
    fun logOut(){
        tokenPersistence.deleteToken()
        navigate(NavigationState.Login())
    }

    fun setTermsOfService() {
        navigate(NavigationState.TermsOfService())
    }
    fun setPrivacyPolicy() {
        navigate(NavigationState.PrivacyPolicy())
    }

    fun requestAuthenticationFromOrderPaymentGuid(orderPaymentGuid: String) {
        viewModelScope.launch(globalCoroutineExceptionHandler + Dispatchers.Default) {
            val response = repository.requestAuthenticationFromOrderPaymentGuid(orderPaymentGuid)
            snackbarStateMutableStateFlow.value = SnackBarState.PhoneNumberAuthRequested()
        }
    }

    fun setSubmitted() {
        navigationStateMutableStateFlow.value = NavigationState.LoginSubmitted()
    }

    fun cancelEnrollment() = Unit
}