package ui.login

import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.IntrinsicSize
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.material.Text
import androidx.compose.material3.Button
import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import org.koin.compose.koinInject
import ui.PhoneEntryField

@Composable
fun LoginRequest(
    loginViewModel: LoginViewModel = koinInject(),
    onSubmitted: () -> Unit,
) {
    Column(
        modifier = Modifier.fillMaxWidth().padding(16.dp),
        horizontalAlignment = Alignment.CenterHorizontally,
        verticalArrangement = Arrangement.spacedBy(8.dp)
    ) {
        Text("Welcome to the Enroll & Pay Consumer Portal", textAlign = TextAlign.Center)
        Text("Please enter your phone number to log in", textAlign = TextAlign.Center)
        Row(modifier = Modifier.height(IntrinsicSize.Min)) {
            CountryCodeDropDown(
                countryCodeStateFlow = loginViewModel.countryCodeStateFlow,
                onCountryCodeChanged = loginViewModel::countryCodeSelected
            )
            PhoneEntryField(
                phoneStateFlow = loginViewModel.phoneNumberState,
                onPhoneNumberChanged = loginViewModel::updatePhoneNumber,
                onEnterPressed = { loginViewModel.submitPhoneNumber(onSubmitted) },
            )
        }
        val isButtonEnable by loginViewModel.isButtonEnableStateFlow.collectAsState()
        Button(
            onClick = { loginViewModel.submitPhoneNumber(onSubmitted) },
            enabled = isButtonEnable
        ) {
            Text("Log In", color = if (isButtonEnable) Color.White else Color.Black)
        }
    }
}