package model.impl

import io.ktor.client.HttpClient
import io.ktor.client.call.body
import io.ktor.client.request.bearerAuth
import io.ktor.client.request.get
import io.ktor.client.request.post
import io.ktor.client.request.setBody
import io.ktor.client.statement.bodyAsText
import io.ktor.http.ContentType
import io.ktor.http.contentType
import io.ktor.http.path
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json
import model.ConsumerInformation
import model.Payments
import model.ReferredBusiness
import model.Repository
import model.SmsAuthenticationBody
import model.TokenAuthenticationBody
import model.TokenAuthenticationResponse
import persistence.TokenPersistence
import receipt.Receipt

class RepositoryKtor(
    private val httpClient: HttpClient,
    private val tokenPersistence: TokenPersistence,
) : Repository {

    private var consumerInformationCached: ConsumerInformation? = null

    override suspend fun getConsumerInformation(): ConsumerInformation? {
        if (consumerInformationCached == null) {
            val response = httpClient.get {
                bearerAuth(tokenPersistence.getToken()!!)
                url {
                    path("consumerInformation")
                }
            }
            if (response.status.value in 200..299) {
                consumerInformationCached = Json.decodeFromString<ConsumerInformation>(response.bodyAsText())
            } else {
                println("Invalid response from server: ${response.bodyAsText()}")
            }
        }
        return consumerInformationCached
    }

    override suspend fun getMerchantInformation(consumerLoyaltyProgramGuid: String, forceRefresh: Boolean): Payments {
        val response = httpClient.get {
            bearerAuth(tokenPersistence.getToken()!!)
            url {
                path("orderInformation")
                parameters.append("consumerLoyaltyProgramGuid", consumerLoyaltyProgramGuid)
            }
        }
        return Json.decodeFromString<Payments>(response.bodyAsText())
    }

    override suspend fun getEReceiptInformation(orderPaymentGuid: String, forceRefresh: Boolean): Receipt {
        val response = httpClient.get {
            url {
                path("eReceipt")
                parameters.append("orderPaymentGuid", orderPaymentGuid)
            }
        }
        return Json.decodeFromString<Receipt>(response.bodyAsText())
    }

    override suspend fun sendBusinessEnrollmentInformation(referredBusiness: ReferredBusiness) {
        val response = httpClient.get {
            url {
                path("referredBusiness")
                parameters.append("potentialCustomer", Json.encodeToString(referredBusiness))
            }
        }
        println(response)
        return Unit
    }

    override suspend fun submitPhoneNumber(phoneNumber: Long) {
        httpClient.post {
            contentType(ContentType.Application.Json)
            url {
                path("sms-authentication")
            }
            setBody(Json.encodeToString(SmsAuthenticationBody(phoneNumber)))
        }
    }

    override suspend fun authenticateGuid(guid: String): String {
        val response = httpClient.post {
            contentType(ContentType.Application.Json)
            url {
                path("authentication-response")
            }
            setBody(TokenAuthenticationBody(guid))
        }
        return response.body<TokenAuthenticationResponse>().token
    }

    override suspend fun requestAuthenticationFromOrderPaymentGuid(guid: String) {
        val response = httpClient.post {
            contentType(ContentType.Application.Json)
            url{
                path("manageAccount")
                parameters.append("orderPaymentGuid", guid)
            }
        }
    }
}