package ui.eReceipt

import androidx.compose.foundation.layout.padding
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp

@Composable
fun ReceiptHeaderInformation(vararg strings: String) {
    strings.forEachIndexed { index, string ->
        var modifier: Modifier = Modifier
        when (index) {
            0 -> {
                modifier = Modifier.padding(top = 16.dp)
            }

            strings.size - 1 -> {
                modifier = Modifier.padding(bottom = 16.dp)
            }
        }
        Text(
            string,
            modifier = Modifier.padding(horizontal = 16.dp).then(modifier)
        )

    }
}