package ui.eReceipt

import androidx.compose.foundation.BorderStroke
import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.widthIn
import androidx.compose.material.Divider
import androidx.compose.material3.Card
import androidx.compose.material3.CardDefaults
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.dp
import formatDecimalToCurrency
import receipt.Receipt
import ui.Colors

@Composable
fun ReceiptContent(eReceipt: Receipt) {
    Column(
        modifier = Modifier
            .background(Color.White)
            .widthIn(0.dp, maxWidth)
    ) {

        Card(
            border = BorderStroke(1.dp, Colors.Secondary),
            modifier = Modifier.fillMaxWidth()
                .padding(8.dp),
            colors = CardDefaults.cardColors(
                containerColor = Color.White
            )
        ) {
            val date = eReceipt.transactionDetails.getOrElse("Date", {""})
            val time = eReceipt.transactionDetails.getOrElse("Time", {""})
            ReceiptHeaderInformation(
                "Order #: ${eReceipt.orderPaymentGuid.takeLast(12)}",
                "Date: $date $time"
            )
        }

        Spacer(modifier = Modifier.height(16.dp))

        eReceipt.paymentDetails.forEach {
            if (it.key == "Total") {
                Divider(
                    modifier = Modifier
                        .height(1.dp)
                        .padding(horizontal = 16.dp),
                    color = Color.Black
                )
            }
            SpacedTextDisplay(
                label = it.key,
                amount = it.value.formatDecimalToCurrency(),
                Modifier.padding(horizontal = 20.dp, vertical = 2.dp),
                color = if (it.key == "Reward") {
                    Colors.Secondary
                } else {
                    Color.Black
                },
                fontWeight = if(it.key == "Total") {
                    FontWeight.Bold
                } else {
                    FontWeight.Normal
                },
            )
        }
    }
}