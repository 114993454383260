package model

import kotlinx.serialization.Serializable

@Serializable
data class Location(
    val locationId: Int? = -1,
    val phoneNumberId: Int? = -1,
    val address: String? = "",
    val phoneNumber: Long? = 0L,
    val merchantId: Int? = -1,
    val name: String? = "",
)
