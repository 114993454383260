package receipt

import kotlinx.datetime.LocalDateTime
import kotlinx.datetime.format
import kotlinx.datetime.format.byUnicodePattern
import kotlinx.serialization.Serializable
import model.Location

@Serializable
class Receipt(
    val orderPaymentGuid: String,
    val consumerLoyaltyProgramGuid: String?,
    val locationDetails: LocationDetails,
    val paymentType: String?,
    val transactionDetails: LinkedHashMap<String, String>,
    val lineItems: List<LineItem>?,
    val paymentDetails: LinkedHashMap<String, Double>,
    val rewardBalanceMessage: String?,
    val creditPricingFooter: String?,
    val signatureBase64String: String?,
) {
    var endSpaces = 0

    @Serializable
    class LocationDetails(
        val name: String,
        val address: String,
        val phoneNumber: String,
    ) {
        constructor(location: Location): this(location.name.orEmpty(), location.address.orEmpty(), location.phoneNumber.formatPhoneNumber())

        companion object {
            fun Long?.formatPhoneNumber(): String {
                return this?.toString()?.let {
                    if (it.startsWith("1") && it.length == 11) {
                        it.toPhoneNumberFormat()
                    } else {
                        it
                    }
                } ?: ""
            }
        }
    }

    @Serializable
    class LineItem(
        val quantity: Double,
        val name: String,
        val value: Double,
        val addOns: List<LineItem>? = null,
    ) {}

    constructor(
        orderPaymentGuid: String, consumerLoyaltyProgramGuid: String?, locationDetails: LocationDetails,
        paymentType: String?, invoiceId: String?, orderGuid: String, dateTime: LocalDateTime?, decision: String,
        cardType: String?, maskedCardNumber: String?, authCode: String?, entryType: String?, additionalId: String?,
        referenceNumber: String?, globalUid: String?, transactionNumber: String?, batchNumber: String?,
        avsResponse: String?, aid: String?, remainingBalance: String?, cardHolderName: String?,
        lineItems: List<LineItem>?, subTotal: Double?, tax: Double?, tip: Double?, reward: Double?,
        discount: Double?, total: Double?, otherPayments: Double?, approved: Double?, balanceRemaining: Double?,
        rewardBalanceMessage: String?, creditPricingFooter: String?, signatureBase64String: String?, totalVoided: Double? = null,
    ) : this(
        orderPaymentGuid = orderPaymentGuid,
        consumerLoyaltyProgramGuid = consumerLoyaltyProgramGuid,
        locationDetails = locationDetails,
        paymentType = paymentType,
        transactionDetails = LinkedHashMap<String, String>().apply {
            invoiceId?.let { set("Invoice", it) }
            set("Order ID", orderGuid.takeLast(9))
            dateTime?.let {
                set("Date", it.formatDate())
                set("Time", it.formatTime())
            }
            set("Decision", decision)
            cardType?.let { set("Card Type", it) }
            maskedCardNumber?.let { set("Card", it) }
            authCode?.let { set("Auth. Code", it) }
            entryType?.let { set("Entry", it) }
            additionalId?.let { set("Additional ID", it) }
            referenceNumber?.let { set("Ref. #", it) }
            globalUid?.let { set("Global UID", it) }
            transactionNumber?.let { set("Transaction #", it) }
            batchNumber?.let { set("Batch #", it) }
            avsResponse?.let { set("AVS Response", it) }
            aid?.let { set("AID", it) }
            remainingBalance?.let { set("Remaining Balance", it) }
            cardHolderName?.let { set("Cardholder Name", it) }
        },
        lineItems = lineItems,
        paymentDetails = LinkedHashMap<String, Double>().apply {
            subTotal?.let { set("Sale", subTotal) }
            tax?.let { set("Tax", it) }
            tip?.let { set("Tip", it) }
            reward?.let { set("Reward", it) }
            discount?.let { set("Discount", it) }
            total?.let { set("Total", it) }
            otherPayments?.let { set("Other Payments", it) }
            approved?.let { set("Approved", it) }
            balanceRemaining?.let { set("Balance\nRemaining", it) }
            totalVoided?.let { set("Total Voided", it) }
        },
        rewardBalanceMessage = rewardBalanceMessage,
        creditPricingFooter = creditPricingFooter,
        signatureBase64String = signatureBase64String,
    )

    constructor(
        orderPaymentGuid: String, consumerLoyaltyProgramGuid: String?, locationDetails: LocationDetails,
        paymentType: String?, invoiceId: String?, orderGuid: String, dateTime: LocalDateTime?, decision: String,
        cardType: String?, maskedCardNumber: String?, authCode: String?, entryType: String?, additionalId: String?,
        referenceNumber: String?, globalUid: String?, transactionNumber: String?, batchNumber: String?,
        avsResponse: String?, aid: String?, cardHolderName: String?, lineItems: List<LineItem>?,
        totalVoided: Double, rewardBalanceMessage: String?,
    ) : this(
        orderPaymentGuid = orderPaymentGuid,
        consumerLoyaltyProgramGuid = consumerLoyaltyProgramGuid,
        locationDetails = locationDetails,
        paymentType = paymentType,
        invoiceId = invoiceId,
        orderGuid = orderGuid,
        dateTime = dateTime,
        decision = decision,
        cardType = cardType,
        maskedCardNumber = maskedCardNumber,
        authCode = authCode,
        entryType = entryType,
        additionalId = additionalId,
        referenceNumber = referenceNumber,
        globalUid = globalUid,
        transactionNumber = transactionNumber,
        batchNumber = batchNumber,
        avsResponse = avsResponse,
        aid = aid,
        remainingBalance = null,
        cardHolderName = cardHolderName,
        lineItems = lineItems,
        subTotal = null,
        tax = null,
        tip = null,
        reward = null,
        discount = null,
        total = null,
        otherPayments = null,
        approved = null,
        balanceRemaining = null,
        rewardBalanceMessage = rewardBalanceMessage,
        creditPricingFooter = null,
        signatureBase64String = null,
        totalVoided = totalVoided
    )

    companion object {
        fun Int.getCardTypeName(): String? {
            return when (this) {
                1 -> return "AMERICAN EXPRESS"
                2 -> return "VISA"
                3 -> return "MASTERCARD"
                4 -> return "DISCOVER"
                5 -> return "ELECTRON"
                6 -> return "MAESTRO"
                7 -> return "DANKORT"
                8 -> return "INTERPAYMENT"
                9 -> return "UNIONPAY"
                10 -> return "DINERS"
                11 -> return "JCB"
                12 -> return "Gift"
                else -> null
            }
        }

        fun LocalDateTime.formatDate(): String {
            val displayDate = this.format(LocalDateTime.Format {
                byUnicodePattern("MM/dd/yy ")
            })

            return displayDate
        }

        fun LocalDateTime.formatTime(): String {
            val hour = this.hour
            val (formattedHour, period) = hour.takeIf { it < 13 }?.let { it to "AM" } ?: ((hour - 12) to "PM")
            val formattedMinutes = this.minute.toString().padStart(2, '0')
            val displayTime = "$formattedHour:$formattedMinutes $period"

            return displayTime
        }

        fun String.toPhoneNumberFormat(): String {
            val newNumber = when (this.length) {
                10 -> this
                11 -> this.drop(1)
                else -> return ""
            }

            val string = StringBuilder("(")
                .append(newNumber.take(3))
                .append(")")
                .append("-")
                .append(newNumber.drop(3).take(3))
                .append("-")
                .append(newNumber.drop(6))

            return string.toString()
        }
    }
}