import io.ktor.client.HttpClient
import model.Repository
import model.impl.RepositoryKtor
import model.network.FlavorInjectedHttpClient
import org.koin.core.module.dsl.bind
import org.koin.core.module.dsl.factoryOf
import org.koin.core.module.dsl.singleOf
import org.koin.dsl.module
import persistence.MultiPlatformSettingsPersistence
import persistence.Persistence
import persistence.TokenPersistence
import ui.AppViewModel
import ui.login.LoginViewModel

val appModules = module {
    single<HttpClient> { FlavorInjectedHttpClient(get()).getClient() }
    factoryOf(::RepositoryKtor) { bind<Repository>() }
    singleOf(::AppViewModel)
    singleOf(::TokenPersistence)
    singleOf(::MultiPlatformSettingsPersistence) { bind<Persistence>() }
    singleOf(::LoginViewModel)
    single<Flavor> { getFlavor() }
    single<ConfigFile> { getClientConfig() }
}