package model

import kotlinx.serialization.Serializable

@Serializable
data class Card(
    val cardId: Int? = -1,
    val consumerId: Int? = -1,
    val nameOnCard: String? = "",
    val panMasked: String? = "",
    val linkedDateTime: String? = null,
)