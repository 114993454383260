@file:OptIn(org.jetbrains.compose.resources.InternalResourceApi::class)

package linx.linxapp.generated.resources

import kotlin.OptIn
import org.jetbrains.compose.resources.StringResource

private object CommonMainString0 {
  public val empty_string: StringResource by 
      lazy { init_empty_string() }

  public val error_retrieving_ereceipt: StringResource by 
      lazy { init_error_retrieving_ereceipt() }

  public val referMessage1: StringResource by 
      lazy { init_referMessage1() }

  public val referMessage2: StringResource by 
      lazy { init_referMessage2() }

  public val thank_you_we_will_message: StringResource by 
      lazy { init_thank_you_we_will_message() }
}

internal val Res.string.empty_string: StringResource
  get() = CommonMainString0.empty_string

private fun init_empty_string(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:empty_string", "empty_string",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/linx.linxapp.generated.resources/values/strings.commonMain.cvr", 10, 20),
    )
)

internal val Res.string.error_retrieving_ereceipt: StringResource
  get() = CommonMainString0.error_retrieving_ereceipt

private fun init_error_retrieving_ereceipt(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:error_retrieving_ereceipt", "error_retrieving_ereceipt",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/linx.linxapp.generated.resources/values/strings.commonMain.cvr", 31, 69),
    )
)

internal val Res.string.referMessage1: StringResource
  get() = CommonMainString0.referMessage1

private fun init_referMessage1(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:referMessage1", "referMessage1",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/linx.linxapp.generated.resources/values/strings.commonMain.cvr", 101, 77),
    )
)

internal val Res.string.referMessage2: StringResource
  get() = CommonMainString0.referMessage2

private fun init_referMessage2(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:referMessage2", "referMessage2",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/linx.linxapp.generated.resources/values/strings.commonMain.cvr", 179, 69),
    )
)

internal val Res.string.thank_you_we_will_message: StringResource
  get() = CommonMainString0.thank_you_we_will_message

private fun init_thank_you_we_will_message(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:thank_you_we_will_message", "thank_you_we_will_message",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/linx.linxapp.generated.resources/values/strings.commonMain.cvr", 249, 117),
    )
)
