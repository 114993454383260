import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.layout.widthIn
import androidx.compose.material.Icon
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.automirrored.filled.ArrowBack
import androidx.compose.material.icons.automirrored.filled.ExitToApp
import androidx.compose.material3.IconButton
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.ColorFilter
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import linx.linxapp.generated.resources.Res
import linx.linxapp.generated.resources.primary_logo_final
import model.NavigationState
import org.jetbrains.compose.resources.painterResource
import ui.AppViewModel
import ui.Colors

@Composable
fun AppBar(viewModel: AppViewModel) {
    val backStack by viewModel.backStackStateFlow.collectAsState()
    val buttonGutterWidth = 48.dp
    Row(
        modifier = Modifier.fillMaxWidth().background(appBarBackgroundColor(viewModel)),
        verticalAlignment = Alignment.CenterVertically
    ) {
        Box(modifier = Modifier.width(buttonGutterWidth)) {
            AppBarNavigation(backStack, viewModel)
        }
        Box(modifier = Modifier.weight(1f), contentAlignment = Alignment.Center) {
            AppBarTitle(viewModel)
        }
        Box(modifier = Modifier.width(buttonGutterWidth)) {
            if (backStack.isNotEmpty()) {
                AppBarActions(viewModel)
            }
        }
    }
}

private fun appBarBackgroundColor(viewModel: AppViewModel): Color {
    return when (viewModel.navigationStateFlow.value) {
        is NavigationState.MerchantInformation -> Colors.Primary
        is NavigationState.EReceipt -> Colors.Default
        else -> Color.White
    }
}

@Composable
private fun AppBarTitle(viewModel: AppViewModel) {
    Box(modifier = Modifier, contentAlignment = Alignment.Center) {
        Image(
            painterResource(Res.drawable.primary_logo_final),
            contentDescription = "",
            modifier = Modifier.widthIn(0.dp, 100.dp),
            colorFilter = if (viewModel.navigationStateFlow.value is NavigationState.MerchantInformation) {
                ColorFilter.tint(Color.Black)
            } else {
                null
            }
        )
    }
}

@Composable
private fun AppBarNavigation(backStack: List<NavigationState>, viewModel: AppViewModel) {
    if (backStack.size > 1) {
        IconButton(
            onClick = viewModel::popBackStack,
            modifier = Modifier.size(48.dp)
        ) {
            Icon(
                imageVector = Icons.AutoMirrored.Filled.ArrowBack,
                contentDescription = ""
            )
        }
    }
}

@Composable
private fun AppBarActions(viewModel: AppViewModel) {
    when (viewModel.navigationStateFlow.value) {
        is NavigationState.ConsumerInformation -> Box() {
            IconButton(onClick = viewModel::requestLogOut) {
                Icon(
                    Icons.AutoMirrored.Filled.ExitToApp,
                    contentDescription = "",
                )
            }
            Text("Logout", fontSize = 8.sp, modifier = Modifier.align(Alignment.Center).padding(top = 32.dp))
        }
        else -> Unit
    }
}