package ui.consumerInformation

import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.material.Text
import androidx.compose.material3.CardDefaults
import androidx.compose.material3.ElevatedCard
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.platform.LocalUriHandler
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.text.style.TextDecoration
import androidx.compose.ui.unit.dp
import linx.linxapp.generated.resources.Res
import linx.linxapp.generated.resources.referMessage1
import linx.linxapp.generated.resources.referMessage2
import org.jetbrains.compose.resources.stringResource
import ui.Colors

@Composable
fun ReferProgramCardView() {
    val uriHandler = LocalUriHandler.current
    ElevatedCard(
        modifier = Modifier
            .fillMaxWidth()
            .padding(16.dp)
            .clickable {
                uriHandler.openUri("https://www.enrollandpay.com/contact/")
            },
        colors = CardDefaults.cardColors(
            containerColor = Colors.Highlight
        ),
    ) {
        Column(
            modifier = Modifier
                .fillMaxWidth()
                .padding(8.dp),
            verticalArrangement = Arrangement.Center,
            horizontalAlignment = Alignment.CenterHorizontally
        ) {
            Text(
                stringResource(Res.string.referMessage1),
                textAlign = TextAlign.Center
            )
            Text(
                stringResource(Res.string.referMessage2),
                textAlign = TextAlign.Center,
                textDecoration = TextDecoration.Underline
            )
        }
    }
}