package ui.consumerInformation

import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.material.CircularProgressIndicator
import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import kotlinx.coroutines.flow.StateFlow
import model.ConsumerInformation
import model.MerchantFullInformation

@Composable
fun ConsumerInformationScreen(
    onMerchantClicked: (MerchantFullInformation) -> Unit,
    fetchConsumerInformation: StateFlow<ConsumerInformation?>,
    merchantCardView: @Composable (MerchantFullInformation, (MerchantFullInformation) -> Unit) -> Unit,
    shouldShowScrollbar: Boolean,
) {
    val _consumerInformation by fetchConsumerInformation.collectAsState()
    val consumerInformation = _consumerInformation
    Column(
        modifier = Modifier
            .fillMaxWidth()
            .fillMaxHeight(),
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        Box(
            contentAlignment = Alignment.Center,
            modifier = Modifier
                .fillMaxWidth()
        ) {
            LoyaltyProgramView()
        }
        if (consumerInformation != null) {
            ConsumerInformationDisplay(consumerInformation)
            ConsumerCardDisplay(consumerInformation.cards.filterNotNull(), shouldShowScrollbar)
            Column(modifier = Modifier.weight(1f).fillMaxWidth()) {
                ConsumerStoreInformationDisplay(consumerInformation.merchants.filterNotNull(), merchantCardView, onMerchantClicked, shouldShowScrollbar)
            }
            ReferProgramCardView()
        } else {
            CircularProgressIndicator()
        }
    }
}