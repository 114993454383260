package persistence

import com.russhwolf.settings.Settings

class MultiPlatformSettingsPersistence : Persistence {
    val settings = Settings()

    override fun get(key: String): String? {
        return settings.getStringOrNull(key)
    }

    override fun set(key: String, value: String) {
        settings.putString(key, value)
    }

    override fun delete(key: String) {
        settings.remove(key)
    }
}