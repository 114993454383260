package model

interface Repository {
    suspend fun getConsumerInformation(): ConsumerInformation?
    suspend fun getMerchantInformation(consumerLoyaltyProgramGuid: String, forceRefresh: Boolean = false): Payments
    suspend fun getEReceiptInformation(orderPaymentGuid: String, forceRefresh: Boolean = false): EReceipt
    suspend fun sendBusinessEnrollmentInformation(referredBusiness: ReferredBusiness): Unit
    suspend fun submitPhoneNumber(phoneNumber: Long)
    suspend fun authenticateGuid(guid: String): String
    suspend fun requestAuthenticationFromOrderPaymentGuid(guid: String)
}