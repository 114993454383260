package model

import kotlinx.serialization.Serializable

@Serializable
data class AdditionalInformation(
    var refNum: String? = null,
    var extDataEDCTYPE: String? = null,
    var recapLanguage: String? = null,
    var remainingBalance: String? = null,
    var cardType: String? = null,
    var hostCode: String? = null,
    var extDataGlobalUid: String? = null,
    var extDataBatchNum: String? = null,
    var extDataAvsMessgae: String? = null,
    var extDataAid: String? = null,
    var extDataPLNameOnCard: String? = null,
    var panMasked: String? = null,
    var consumerLoyaltyProgramGuid: String? = null,
)
