package ui.consumerInformation

import androidx.compose.foundation.layout.ColumnScope
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.lazy.LazyRow
import androidx.compose.foundation.lazy.items
import androidx.compose.foundation.lazy.rememberLazyListState
import androidx.compose.material.Divider
import androidx.compose.material.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import model.Card
import ui.scrollbar.LazyRowScrollbar

@Composable
fun ColumnScope.ConsumerCardDisplay(cards: List<Card>, shouldShowScrollbar: Boolean) {
    Text(
        "My Cards",
        fontWeight = FontWeight.ExtraBold,
        modifier = Modifier.align(Alignment.Start).padding(start = 16.dp, top = 16.dp),
        fontSize = 20.sp
    )
    val lazyLisState = rememberLazyListState()
    val cardViewHeight = 126.dp //Intrinsic Height can't be used with lazyRows at this time
    LazyRowScrollbar(
        listState = lazyLisState,
        alwaysShowScrollBar = shouldShowScrollbar,
        height = cardViewHeight,
    ) {
        LazyRow(
            state = lazyLisState,
        ) {
            item {
                Spacer(modifier = Modifier.width(16.dp))
            }
            items(cards) { item ->
                if (item.cardId != -1) {
                    CardDisplayView(item, cardViewHeight)
                    Divider(
                        color = Color.Transparent,
                        modifier = Modifier
                            .width(8.dp)
                    )
                }
            }
            item {
                Spacer(modifier = Modifier.width(8.dp))
            }
        }
    }
}