package ui.eReceipt

import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.text.ClickableText
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.SpanStyle
import androidx.compose.ui.text.buildAnnotatedString
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextDecoration
import androidx.compose.ui.text.withStyle
import androidx.compose.ui.unit.TextUnit
import androidx.compose.ui.unit.dp

@Composable
fun PrivacyAndTermsOfService(
    onPrivacyPolicyClicked: () -> Unit,
    onTermsOfServiceClicked: () -> Unit,
) {
    Row {
        ClickableText(
            text = buildAnnotatedString {
                withStyle(
                    style = SpanStyle(
                        color = Color.Blue,
                        fontSize = TextUnit.Unspecified,
                        fontWeight = FontWeight.Normal,
                        textDecoration = TextDecoration.None
                    )
                ) { append("Privacy Statement") }
            },
            onClick = {
                onPrivacyPolicyClicked()
            }
        )

        Spacer(modifier = Modifier.width(4.dp))
        Text("|")
        Spacer(modifier = Modifier.width(4.dp))
        ClickableText(
            text = buildAnnotatedString {
                withStyle(
                    style = SpanStyle(
                        color = Color.Blue,
                        fontSize = TextUnit.Unspecified,
                        fontWeight = FontWeight.Normal,
                        textDecoration = TextDecoration.None
                    )
                ) { append("Terms of Service") }
            },
            onClick = {
                onTermsOfServiceClicked()
            }
        )
    }
}