package ui.eReceipt

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.layout.widthIn
import androidx.compose.foundation.shape.CutCornerShape
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.graphicsLayer
import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.dp

@Composable
fun PerforatedEdge(
    perfWidth: Dp = 20.dp,
    perfHeight: Dp = 20.dp,
    color: Color = Color.White,
    isTopPosition: Boolean = true,
    boxWidth: Dp? = null,
) {
    val perfSideWidth = perfWidth / 2

    val modifier: Modifier = if (boxWidth != null) {
        Modifier.widthIn(0.dp, boxWidth)
    } else {
        Modifier.fillMaxWidth()
    }
    Row(
        modifier = modifier,
        verticalAlignment = Alignment.Bottom
    ) {
        repeat(40) {
            Box(
                modifier = Modifier
                    .graphicsLayer {
                        if (!isTopPosition)
                            rotationX = 180f
                    }
                    .height(perfHeight)
                    .width(perfWidth)
                    .clip(CutCornerShape(topStart = perfSideWidth, topEnd = perfSideWidth))
                    .background(color)
            )
        }
    }
}