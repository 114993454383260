package model

import kotlinx.datetime.LocalDateTime
import kotlinx.serialization.Serializable

@Serializable
data class Order(
    val orderId: Int? = -1,
    val startDateTime: LocalDateTime? = null,
    val locationId: Int? = -1,
    val amountTax: Double? = 0.0,
    val amountGratuity: Double? = 0.0,
    val amountReward: Double? = 0.0,
    val amountDiscount: Double? = 0.0,
    val amountTendered: Double? = 0.0,
)
