package ui.eReceipt

import androidx.compose.foundation.BorderStroke
import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.widthIn
import androidx.compose.material.Divider
import androidx.compose.material3.Card
import androidx.compose.material3.CardDefaults
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.dp
import formatDecimalToCurrency
import model.EReceipt
import nullsBiggerThan
import orZero
import toDateFormat
import ui.Colors

@Composable
fun ReceiptContent(eReceipt: EReceipt?) {
    Column(
        modifier = Modifier
            .background(Color.White)
            .widthIn(0.dp, maxWidth)
    ) {

        Card(
            border = BorderStroke(1.dp, Colors.Secondary),
            modifier = Modifier.fillMaxWidth()
                .padding(8.dp),
            colors = CardDefaults.cardColors(
                containerColor = Color.White
            )
        ) {
            val date = eReceipt!!.order?.startDateTime?.toDateFormat()
            ReceiptHeaderInformation(
                "Order #: ${eReceipt.orderPayment?.orderPaymentGuid?.takeLast(12)}",
                "Date: $date"
            )
        }

        Divider(modifier = Modifier.height(8.dp), color = Color.Transparent)

        Divider(modifier = Modifier.height(8.dp), color = Color.Transparent)


        val orderPayment = eReceipt?.orderPayment
        val transactionInformation = buildList {
            if (orderPayment?.amountTotal nullsBiggerThan 0.0) {
                add(
                    Pair(
                        "Subtotal",
                        orderPayment?.amountTotal!!.formatDecimalToCurrency()
                    )
                )
            }

            if (orderPayment?.amountTax nullsBiggerThan 0.0) add(Pair("Tax", orderPayment?.amountTax!!.formatDecimalToCurrency()))
            if (orderPayment?.amountGratuity!! nullsBiggerThan 0.0) add(
                Pair(
                    "Tip", orderPayment.amountGratuity!!.formatDecimalToCurrency()
                )
            )
            if (orderPayment.amountReward!! != 0.0) add(
                Pair(
                    "Rewards",
                    "-" + orderPayment.amountReward?.formatDecimalToCurrency()
                )
            )
        }

        transactionInformation.forEach {
            SpacedTextDisplay(
                label = it.first,
                amount = it.second,
                Modifier.padding(horizontal = 20.dp, vertical = 4.dp),
                color = if (it.first == "Rewards") {
                    Colors.Secondary
                } else {
                    Color.Black
                }
            )
        }

        if (transactionInformation.isNotEmpty())
            Divider(
                modifier = Modifier
                    .height(1.dp)
                    .padding(horizontal = 16.dp),
                color = Color.Black
            )

        Row {
            eReceipt!!.orderPayment?.amountTendered?.let {
                val double:Double = it + eReceipt.orderPayment?.amountGratuity.orZero()
                SpacedTextDisplay(
                    label = "Total",
                    amount = double.formatDecimalToCurrency(),
                    fontWeight = FontWeight.Bold
                )
            }
        }
    }
}