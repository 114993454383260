package persistence

class TokenPersistence(val persistence: Persistence) {
    fun getToken(): String? {
        return persistence.get("token")
    }

    fun setToken(token: String) {
        persistence.set("token", token)
    }

    fun deleteToken() {
        persistence.delete("token")
    }
}