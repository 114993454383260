package model

import kotlinx.serialization.Serializable

@Serializable
data class Offer(
    val merchantId: Int? = -1,
    val receiveAmount: Double? = 0.0,
    val qualifierAmount: Double? = 0.0,
    val statusType: Int? = -1,
    val offerId: Int? = -1,
    val receiveType: Int? = -1,
)