package model

sealed class ClientNavRequestEvent() {
    var isRead: Boolean = false
        get() {
            val read = field
            field = true
            return read
        }
        private set

    object Nothing : ClientNavRequestEvent()
    class Back: ClientNavRequestEvent()
    class HashStateChangeEvent(val newHash: String) : ClientNavRequestEvent()
}