import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.widthIn
import androidx.compose.material.MaterialTheme
import androidx.compose.material.Scaffold
import androidx.compose.runtime.Composable
import androidx.compose.runtime.DisposableEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import model.ClientNavRequestEvent
import org.koin.compose.KoinApplication
import org.koin.compose.koinInject
import org.koin.core.context.stopKoin
import ui.AppViewModel
import ui.Colors
import ui.dialogs.HandleDialogs
import ui.snackbars.HandleSnackBars


@Composable
fun App(
    clientNavRequestStateFlow: StateFlow<ClientNavRequestEvent> = MutableStateFlow(ClientNavRequestEvent.Nothing),
    locationListener: (String) -> Unit = {},
    titleListener: (String) -> Unit = {},
) {
    KoinApplication(application = {
        modules(appModules)
    }) {
        DisposableEffect(null) {
            onDispose {
                stopKoin()
            }
        }
        val viewModel: AppViewModel = koinInject()
        titleListener(viewModel.clientName)
        MaterialTheme {
            Box(
                modifier = Modifier
                    .fillMaxSize()
                    .background(Colors.Default),
                contentAlignment = Alignment.Center
            ) {
                Scaffold(
                    snackbarHost = {
                        HandleSnackBars(viewModel.snackbarStateStateFlow)
                    },
                    topBar = {
                        AppBar(viewModel)
                    },
                    modifier = Modifier.widthIn(0.dp, 500.dp)
                ) { _ ->
                    AppContent(viewModel, locationListener)
                }
            }
        }
        ClientNavigation(viewModel, clientNavRequestStateFlow, locationListener)
        HandleDialogs(viewModel)
    }
}

@Composable
private fun ClientNavigation(
    viewModel: AppViewModel,
    clientNavRequestStateFlow: StateFlow<ClientNavRequestEvent>,
    locationListener: (String) -> Unit,
) {
    val _clientNavRequest by clientNavRequestStateFlow.collectAsState()
    val clientNavRequest = _clientNavRequest
    if (!clientNavRequest.isRead) {
        when (clientNavRequest) {
            ClientNavRequestEvent.Nothing -> Unit
            is ClientNavRequestEvent.Back -> viewModel.popBackStack()
            is ClientNavRequestEvent.HashStateChangeEvent -> viewModel.hashStateChangeEvent(clientNavRequest.newHash, locationListener)
        }
    }
}