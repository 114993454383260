package ui.eReceipt

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.widthIn
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.text.selection.DisableSelection
import androidx.compose.foundation.text.selection.SelectionContainer
import androidx.compose.foundation.verticalScroll
import androidx.compose.material.CircularProgressIndicator
import androidx.compose.material.Divider
import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.unit.dp
import kotlinx.coroutines.flow.StateFlow
import model.EReceipt
import ui.Colors

val maxWidth = 300.dp

@Composable
fun EReceiptScreen(
    eReceiptStateFlow: StateFlow<EReceipt?>,
    onCancelEnrollmentClicked: () -> Unit,
    onTermsOfServiceClicked: () -> Unit,
    onPrivacyPolicyClicked: () -> Unit,
    onManageAccountClicked: (String) -> Unit,
    isTokenAvailable: Boolean,
) {
    val scrollState = rememberScrollState()
    SelectionContainer {
        Column(
            modifier = Modifier
                .fillMaxWidth()
                .fillMaxHeight()
                .background(Colors.Default)
                .padding(16.dp)
                .verticalScroll(scrollState),
            horizontalAlignment = Alignment.CenterHorizontally,
        ) {

            val _eReceipt by eReceiptStateFlow.collectAsState()
            val eReceipt = _eReceipt

            if (eReceipt != null) {
                PerforatedEdge(
                    boxWidth = maxWidth
                )
                Divider(
                    modifier = Modifier
                        .height(32.dp)
                        .widthIn(0.dp, maxWidth),
                    color = Color.White
                )
                TitleBanner(eReceipt)
                ReceiptContent(eReceipt)
                eReceipt.orderPayment?.additionalInformation?.recapLanguage
                    ?.takeIf { it.isNotBlank() }?.let {
                        RecapLanguageBanner(it)
                    }
                eReceipt.orderPayment?.additionalInformation?.let {
                    TransactionInformation(it)
                }

                Divider(
                    modifier = Modifier
                        .height(32.dp)
                        .widthIn(0.dp, maxWidth),
                    color = Color.White
                )
                PerforatedEdge(
                    isTopPosition = false,
                    boxWidth = maxWidth
                )
                DisableSelection {
                    Links(
                        isTokenAvailable = isTokenAvailable,
                        onCancelEnrollmentClicked = onCancelEnrollmentClicked,
                        onTermsOfServiceClicked = onTermsOfServiceClicked,
                        onPrivacyPolicyClicked = onPrivacyPolicyClicked
                    ) {
                        onManageAccountClicked(eReceipt.orderPayment?.orderPaymentGuid!!)
                    }
                }
            } else {
                CircularProgressIndicator(modifier = Modifier.align(Alignment.CenterHorizontally))
            }
        }
    }
}