package ui.consumerInformation

import androidx.compose.foundation.layout.height
import androidx.compose.foundation.lazy.LazyListScope
import androidx.compose.foundation.lazy.items
import androidx.compose.material.Divider
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.unit.dp
import model.MerchantFullInformation

fun LazyListScope.ConsumerStoreInformationDisplay(
    merchants: List<MerchantFullInformation>,
    merchantCardView: @Composable (MerchantFullInformation, (MerchantFullInformation) -> Unit) -> Unit,
    onMerchantClicked: (MerchantFullInformation) -> Unit,
) {
        items(merchants.filter { it.name?.isNotBlank() == true }) {
            merchantCardView(it, onMerchantClicked)
            Divider(
                color = Color.White,
                modifier = Modifier
                    .height(16.dp)
            )
        }

}