import androidx.compose.ui.ExperimentalComposeUiApi
import androidx.compose.ui.window.CanvasBasedWindow
import kotlinx.browser.document
import kotlinx.browser.window
import kotlinx.coroutines.flow.MutableStateFlow
import model.ClientNavRequestEvent
import org.jetbrains.skiko.wasm.onWasmReady

@OptIn(ExperimentalComposeUiApi::class)
fun main() {

    if (window.location.pathname != "/") {
        if (window.location.pathname.startsWith("/en-us/p/")) {
            val paymentGuid = window.location.pathname.split("/en-us/p/").last()
            window.localStorage.setItem("redirect", "EReceipt=$paymentGuid")
        } else if (window.location.pathname.startsWith("/en-us/terms-of-service")) {
            window.localStorage.setItem("redirect", "TermsOfService")
        } else if (window.location.pathname.startsWith("/en-us/privacy-statement")) {
            window.localStorage.setItem("redirect", "PrivacyPolicy")
        }
        window.location.pathname = "/"
        return
    } else {
        window.localStorage.getItem("redirect")?.let { redirectHash ->
            window.localStorage.removeItem("redirect")
            window.location.hash = redirectHash
        }
    }

    if (window.matchMedia("(pointer: coarse)").matches) {
        //browser supports touchScreen
        println("Pointer coarse mode is enabled.")
        configFile.shouldShowScrollBar = false
    } else {
        //Browser does not support touchScreen
        println("Pointer coarse mode is not enabled.")
    }

    onWasmReady {
        val browserNavRequest: MutableStateFlow<ClientNavRequestEvent> = MutableStateFlow(
            ClientNavRequestEvent.HashStateChangeEvent(window.location.hash.split("#").last())
        )
        window.onhashchange = { e ->
            browserNavRequest.value = ClientNavRequestEvent.HashStateChangeEvent(window.location.hash.split("#").last())
            Unit
        }

        CanvasBasedWindow("Enroll & Pay", "ComposeTarget") {
            App(browserNavRequest,
                locationListener = { it ->
                    if (it != "") {
                        window.location.hash = it
                    } else {
                        window.history.replaceState(null, document.title, ".")
                    }
                },
                titleListener = {
                    document.title = it
                },
            )
        }
    }
}