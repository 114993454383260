package model

import kotlinx.serialization.Serializable

@Serializable
data class ConsumerLoyaltyProgram(
    val consumerLoyaltyProgramGuid: String? = "",
    val loyaltyProgramId: Int? = -1,
    val enrollMerchantId: Int? = -1,
    val enrollLocationId: Int? = -1,
    val pointBalanceCurrent: Double? = 0.0,
    val rewardBalanceCurrent: Double? = 0.0,
    val consumerId: Int? = -1,
    val consumerLoyaltyProgramId: Int? = -1,
)