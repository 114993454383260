package model

import kotlinx.serialization.Serializable

@Serializable
data class AdditionalInformation(
    var refNum: String? = null,
    var extDataEDCTYPE: String? = null,
    var recapLanguage: String? = null,
)
