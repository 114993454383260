package ui.eReceipt

import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.padding
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.dp

@Composable
fun SpacedTextDisplay(
    label: String,
    amount: String,
    modifier: Modifier = Modifier.padding(horizontal = 20.dp, vertical = 8.dp),
    fontWeight: FontWeight = FontWeight.Normal,
    color: Color = Color.Black,
) {
    Row(
        modifier = modifier,
        horizontalArrangement = Arrangement.SpaceBetween
    ) {
        Text(label, fontWeight = fontWeight, modifier = Modifier.weight(1f).then(modifier), color = color)
        Text(amount, fontWeight = fontWeight, modifier = modifier, color = color)
    }
}