package model

import kotlinx.serialization.Serializable

@Serializable
data class ConsumerContact(
    val consumerContactInformationId: Int? = -1,
    val consumerId: Int? = -1,
    val emailAddress: String? = "",
    val phoneNumber: String? = "",
)