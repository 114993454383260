package model

import kotlinx.datetime.Clock
import kotlinx.datetime.LocalDateTime
import kotlinx.datetime.TimeZone
import kotlinx.datetime.toLocalDateTime
import kotlinx.serialization.Serializable

@Serializable
data class OrderPayment(
    val consumerLoyaltyProgramGuid: String? = "",
    val orderPaymentGuid: String? = "",
    val cardId: Int? = -1,
    val amountTotal: Double? = 0.0,
    val updateDateTime: LocalDateTime? = null,
    val orderId: Int? = -1,
    val consumerId: Int? = -1,
    val orderPaymentId: Int? = -1,
    val amountTax: Double? = 0.0,
    val amountGratuity: Double? = 0.0,
    val amountReward: Double? = 0.0,
    val amountDiscount: Double? = 0.0,
    val amountTendered: Double? = 0.0,
    val orderStartTime: LocalDateTime = Clock.System.now().toLocalDateTime(TimeZone.UTC),
    val additionalInformation: AdditionalInformation = AdditionalInformation()
)