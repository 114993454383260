package model

import kotlinx.serialization.Serializable

@Serializable
data class ConsumerLoyaltyProgramTransaction(
    val consumerLoyaltyProgramId: Int? = -1,
    val orderPaymentId: Int? = -1,
    val offerId: Int? = -1,
)
