package model

import kotlinx.serialization.Serializable

@Serializable
data class EReceipt(
    val orderPayment: OrderPayment? = OrderPayment(),
    val order: Order? = Order(),
    val location: Location? = Location(),
    val merchant: Merchant? = Merchant(),
    val consumerLoyaltyProgram: ConsumerLoyaltyProgram? = ConsumerLoyaltyProgram(),
    val consumerLoyaltyProgramTransaction: ConsumerLoyaltyProgramTransaction? = ConsumerLoyaltyProgramTransaction(),
    val offer: Offer? = Offer()
)
