package ui.eReceipt

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.foundation.text.selection.SelectionContainer
import androidx.compose.foundation.verticalScroll
import androidx.compose.material3.LocalTextStyle
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.CompositionLocalProvider
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.AnnotatedString
import androidx.compose.ui.text.SpanStyle
import androidx.compose.ui.text.buildAnnotatedString
import androidx.compose.ui.text.font.FontFamily
import androidx.compose.ui.text.font.FontStyle
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.text.withStyle
import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.TextUnit
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import ui.Colors

val normalTextSize = TextUnit.Unspecified
val pageHeaderFontSize = 20.sp
val subHeader1FontSize = 18.sp
val subHeader2FontSize = 14.sp
val normalSpacing = 11.dp
val largeSpacing = 16.dp
val smallPadding = 4.dp

@Composable
fun PrivacyPolicy() {
    SelectionContainer {
        CompositionLocalProvider(
            LocalTextStyle provides LocalTextStyle.current.copy(
                lineHeight = normalTextSize,
                fontFamily = FontFamily.Serif,
                textAlign = TextAlign.Justify
            ),
        ) {
            Column(
                verticalArrangement = Arrangement.spacedBy(normalSpacing),
                modifier = Modifier
                    .padding(horizontal = 24.dp)
                    .verticalScroll(
                        rememberScrollState()
                    )
            ) {
                SectionSpacer(32.dp)
                PageHeader("ENROLL & PAY PRIVACY POLICY")
                SectionSpacer()
                BoldText("Last Updated: 03/10/2023")
                SectionSpacer()
                NormalText(buildAnnotatedString {
                    append("""This Privacy Policy explains how Payment Integrations, Inc. and its platform, Enroll & Pay. (""")
                    appendBoldItalic(""""Enroll & Pay," "we,"""")
                    append(" or ")
                    appendBoldItalic(""""us"""")
                    append(""") collects, uses, and discloses information about you. This Privacy Policy applies when you use our website located at enrollandpay.com (our """)
                    appendBoldItalic(""""Site"""")
                    append(""") and other online products and services, enroll in an Enroll & Pay loyalty program with one of our participating merchants (""")
                    appendBoldItalic(""""Merchants"""")
                    append("""), interact with us on any other platform or service where we post this Privacy Policy , or engage with us on social media (collectively, our """)
                    appendBoldItalic(""""Services"""")
                    append(").")
                    appendLine()
                    appendLine()
                    append("""We may change this Privacy Policy from time to time. If we make changes, we will notify you by revising the date at the top of this policy and, in some cases, we may provide you with additional notice (such as adding a statement to our website). We encourage you to review this Privacy Policy regularly to stay informed about our information practices and the choices available to you.""")
                    appendLine()
                    appendLine()
                    append("""This Privacy Policy applies to you in different ways, depending on the way you interact with our Services. Below are the different roles in which you can interact with our Services.""")
                })
                Column(
                    modifier = Modifier.padding(start = largeSpacing),
                    verticalArrangement = Arrangement.spacedBy(normalSpacing),
                ) {
                    BulletedItem {
                        NormalText(buildAnnotatedString {
                            withStyle(style = SpanStyle(fontWeight = FontWeight.Bold)) { append("Merchant. ") }
                            append("You are a participating merchant or retailer that partners with us to offer your customers access to our Enroll & Pay loyalty program on your behalf.")
                        })
                    }
                    BulletedItem {
                        NormalText(buildAnnotatedString {
                            withStyle(style = SpanStyle(fontWeight = FontWeight.Bold)) { append("Website Visitor. ") }
                            append("You are visiting our Site.")
                        })
                    }
                    BulletedItem {
                        NormalText(buildAnnotatedString {
                            withStyle(style = SpanStyle(fontWeight = FontWeight.Bold)) { append("Merchant End User. ") }
                            append("""You are enrolled in the Enroll & Pay loyalty program that we provide on behalf of our Merchants. When we provide our Services to you on behalf of our Merchant, the information, including activity data, collected from or about you is known as the "Merchant End User Data."""")
                        })
                    }
                }
                NormalText("The personal information we collect and how we use it may differ if you are a Merchant, Merchant End User, or Website Visitor so we make these distinctions in this Privacy Policy, where applicable.")
                SectionSpacer()
                SubHeader1("CONTENTS")
                Column {
                    CompositionLocalProvider(
                        LocalTextStyle provides LocalTextStyle.current.copy(
                            color = Colors.PrivacyBlue,
                        ),
                    ) {
                        NormalText("Collection of Information")
                        NormalText("Use of Information")
                        NormalText("Sharing of Information")
                        NormalText("Advertising and Analytics")
                        NormalText("Transfer of Information to the United States and Other Countries")
                        NormalText("Do Not Track Notice")
                        NormalText("Children")
                        NormalText("Your Choices")
                        NormalText("Contact Us")
                    }
                }
                SectionSpacer()
                SubHeader1("COLLECTION OF INFORMATION")
                SectionSpacer(4.dp)
                SubHeader2("Information You Provide to Us")
                NormalText("We collect information you provide directly to us in the ways described below.")
                SubSection(
                    "Merchants",
                    "If you are a Merchant, you provide information directly to us when you request a demo, request customer support, or otherwise communicate with us. The types of personal information we may collect include your name, email address, phone number, billing information, postal address, and any other information you choose to provide."
                )
                SubSection(
                    "Website Visitor",
                    "If you are a Website Visitor, you may provide your contact information, such as name, email address, and/or phone number, directly to us when you express interest in our Services."
                )
                SubSection(
                    "Merchant End User",
                    "If you are a Merchant End User, you provide your information to us when you create an account in order to participate in one of the loyalty programs we provide you on behalf of the Merchant, fill out a webform on our Site, make a purchase with one of the Merchants whose loyalty program we provide, communicate with us via third-party platforms, request customer support, or otherwise communicate with us (e.g., via text message). The types of personal information we may collect include your name, email address, phone number, and any other information you choose to provide. If you sign up for multiple loyalty programs, we use the same account information you provide for each Merchant’s loyalty program.\n\n" +
                        "When we provide our Services on behalf of our Merchants, this information, including activity data, collected from or about the Merchant’s End Users is controlled by the Merchant. In those instances, Merchant End User Data is governed by the privacy practices of the Merchant. Therefore, please review the applicable Merchant’s privacy policy or notice outlining how your information will be collected, used, stored, used, and managed. To the extent we collect that information, we do so on behalf of the Merchant."
                )
                SubHeader2("Information We Collect Automatically When You Interact with Us")
                NormalText("When you access our Site or otherwise transact with us through our Services, we automatically collect certain information, including:")
                Column(
                    verticalArrangement = Arrangement.spacedBy(smallPadding),
                ) {
                    BulletedItem {
                        NormalText(buildAnnotatedString {
                            appendBold("Activity Information: ")
                            append("We collect information about your activity on our Site, such as access times, pages viewed, and links clicked.")
                        })
                    }
                    BulletedItem {
                        NormalText(buildAnnotatedString {
                            appendBold("Transactional Information: ")
                            append("When you sign up for an Enroll & Pay account and make a purchase with one of our Merchants, we collect information about the transaction, such as purchase price, date and location of the transaction, token associated with your credit card, and rewards balance.")
                        })
                    }
                    BulletedItem {
                        NormalText(buildAnnotatedString {
                            appendBold("Device and Usage Information: ")
                            append("We collect information about how you access our Site, including data about the device and network you use, such as your hardware model, operating system version, mobile network, IP address, unique device identifiers, browser type, and app version.")
                        })
                    }
                    BulletedItem {
                        NormalText(buildAnnotatedString {
                            appendBold("Information Collected by Cookies and Similar Tracking Technologies: ")
                            append("""We (and our service providers) use tracking technologies, such as cookies and web beacons, to collect information about Website Visitors. Cookies are small data files stored on your hard drive or in device memory that help us improve our Services and your experience, see which areas and features of our Services are popular, and count visits. Web beacons (also known as "pixel tags" or "clear GIFs") are electronic images that we use on our Services and in our emails to help deliver cookies, count visits, and understand usage and campaign effectiveness. For more information about cookies and how to disable them, see the """)
                            appendBlue("Your Choices")
                            append(" section below.")
                        })
                    }
                }
                SectionSpacer()
                SubHeader2("Information We Collect from Other Sources")
                NormalText("We obtain information from third-party sources. For example, we may collect information about Merchant End Users or Website Visitors from advertising networks, data analytics providers, and mailing list providers. Additionally, if you create or log into your Enroll & Pay account through a third-party platform (such as Facebook, Google, or OpenTable), we will have access to certain information from that platform, such as your name, birthday, and profile picture, in accordance with the authorization procedures determined by such platform.")
                SubHeader1("USE OF INFORMATION")
                NormalText("We use the information we collect in various ways when you engage with our Services. Those include to:")
                Column(
                    modifier = Modifier.padding(start = largeSpacing),
                ) {
                    BulletedItem { NormalText("Provide, maintain, and improve our Services;") }
                    BulletedItem { NormalText("Process transactions, determine rewards balance, and send you related information, including confirmations, receipts, invoices, and customer experience surveys;") }
                    BulletedItem { NormalText("Personalize and improve your experience on our Services;") }
                    BulletedItem { NormalText("Send you technical notices, security alerts, and support and administrative messages ;") }
                    BulletedItem { NormalText("Respond to your comments and questions and provide customer service;") }
                    BulletedItem {
                        NormalText(buildAnnotatedString {
                            append("Communicate with you about products, services, and events offered by Enroll & Pay and others and provide news and information that we think will interest you (see the ")
                            appendBlue("Your Choices")
                            append(" section below for information about how to opt out of these communications at any time);")
                        })
                    }
                    BulletedItem { NormalText("Monitor and analyze trends, usage, and activities in connection with our Services (e.g., spending performance) and personalize offers for Merchant End Users;") }
                    BulletedItem { NormalText("Personalize the advertisements you see when you use our Services based on information provided by our advertising partners;") }
                    BulletedItem { NormalText("Facilitate contests, sweepstakes, and promotions and process and deliver entries and rewards;") }
                    BulletedItem { NormalText("Detect, investigate, and prevent security incidents and other malicious, deceptive, fraudulent, or illegal activity and protect the rights and property of Enroll & Pay, our Merchants, and others;") }
                    BulletedItem { NormalText("Debug to identify and repair errors in our Services;") }
                    BulletedItem { NormalText("Comply with our legal and financial obligations; and") }
                    BulletedItem { NormalText("Carry out any other purpose described to you at the time the information was collected.") }
                }
                SectionSpacer()
                SubHeader1("SHARING OF INFORMATION")
                NormalText("We share personal information in the following circumstances or as otherwise described in this policy:")
                Column(
                    modifier = Modifier.padding(start = largeSpacing),
                ) {
                    BulletedItem { NormalText("We share personal information with the Merchants on whose behalf we provide loyalty programs, including Merchant End User Data.") }
                    BulletedItem { NormalText("We share personal information with vendors, service providers, and consultants that need access to personal information in order to perform services for us, such as companies that assist us with web hosting, payment processing, fraud prevention, customer service, and marketing and advertising.") }
                    BulletedItem {
                        NormalText(buildAnnotatedString {
                            append("We share personal information with third parties for their own services and marketing purposes, unless you opt out of this type of sharing by contacting us via one of the methods listed in the ")
                            appendBlue("Contact Us")
                            append(" section below.")
                        })
                    }
                    BulletedItem { NormalText("We may disclose personal information if we believe that disclosure is in accordance with, or required by, any applicable law or legal process, including lawful requests by public authorities to meet national security or law enforcement requirements.") }
                    BulletedItem { NormalText("We may share personal information if we believe that your actions are inconsistent with our user agreements or policies, if we believe that you have violated the law, or if we believe it is necessary to protect the rights, property, and safety of Enroll & Pay, our Merchants, the public, or others.") }
                    BulletedItem { NormalText("We share personal information with our lawyers and other professional advisors where necessary to obtain advice or otherwise protect and manage our business interests.") }
                    BulletedItem { NormalText("We may share personal information in connection with, or during negotiations concerning, any merger, sale of company assets, financing, or acquisition of all or a portion of our business by another company.") }
                    BulletedItem { NormalText("Personal information is shared between and among Enroll & Pay and our current and future parents, affiliates, and subsidiaries and other companies under common control and ownership.") }
                    BulletedItem { NormalText("We share personal information with your consent or at your direction.") }
                }
                NormalText("We also share aggregated or de-identified information that cannot reasonably be used to identify you.")
                SubHeader1("ADVERTISING AND ANALYTICS")
                NormalText(
                    "We allow others to provide analytics services and serve advertisements on our behalf across the web and in mobile apps. If you are a Website Visitor, these entities may use cookies, web beacons, device identifiers, and other technologies to collect information about your use of our Services and other websites and applications, including your IP address, web browser, mobile network information, pages viewed, time spent on pages or in mobile apps, links clicked, and conversion information. This information may be used by Enroll & Pay and others to, among other things, analyze and track data, determine the popularity of certain content, deliver advertising and content targeted to your interests on our Services and other websites, and better understand your online activity.\n\n" +
                        "We also work with third parties to serve ads to you as part of customized campaigns on third-party platforms (such as LinkedIn). As part of these ad campaigns, we or the third-party platforms may convert information about you, such as your email address and phone number, into a unique value that can be matched with a user account on these platforms to allow us to learn about your interests and serve you advertising that is customized to your interests. Note that the third-party platforms may offer you choices about whether you see these types of customized ads."
                )
                SubHeader1("TRANSFER OF INFORMATION TO THE UNITED STATES AND OTHER COUNTRIES")
                NormalText("Enroll & Pay is headquartered in the United States, and we have operations and service providers in the United States, Canada, and other countries. Therefore, we and our service providers may transfer your personal information to, or store or access it in, jurisdictions that may not provide levels of data protection that are equivalent to those of your home jurisdiction. We will take steps to ensure that your personal information receives an adequate level of protection in the jurisdictions in which we process it.")
                SubHeader1("DO NOT TRACK NOTICE")
                NormalText("The Site does not currently support certain browser settings or otherwise respond to Do Not Track requests.")
                SubHeader1("CHILDREN")
                NormalText("This Site is not intended for or directed at children under the age of 13. In addition, we do not knowingly collect personal information from children under the age of 13.")
                SubHeader1("YOUR CHOICES")
                SubSection2("Account Information", buildAnnotatedString {
                    append("If you are a Merchant End User, you may update and correct certain account information we maintain relating to your Merchant loyalty program or deactivate your account at any time by contacting us via one of the methods listed in the ")
                    appendBlue("Contact Us")
                    append(" section below. Please note, we may retain certain information as required by law or for our legitimate business purposes.")
                })
                SubSection2(
                    "Cookies",
                    "Most web browsers are set to accept cookies by default. If you prefer, you can usually adjust your browser settings to remove or reject browser cookies. Please note that removing or rejecting cookies could affect the availability and functionality of our Services."
                )
                SubSection2("Communications Preferences", buildAnnotatedString {
                    append("If you are a Merchant End User, you may opt out of receiving text messages or promotional emails from Enroll & Pay by following the instructions in those communications or by contacting us via one of the methods listed in the ")
                    appendBlue("Contact Us")
                    append(" section below. If you opt out, we may still send you non-promotional emails, such as those about your account or our ongoing business relations.")
                })
                SubHeader1("CONTACT US")
                NormalText("If you have any questions about this Privacy Policy, please contact us at:")
                Column {
                    NormalText("Enroll & Pay")
                    NormalText("514 Via de la Valle Ste 309")
                    NormalText("Solana Beach, California 92075")
                    NormalText("866-942-5500")
                    NormalText(buildAnnotatedString { appendBlue("info@enrollandpay.com") })
                }
                SectionSpacer()
            }
        }
    }
}

@Composable
fun PageHeader(text: String, modifier: Modifier = Modifier) {
    Text(
        modifier = modifier.fillMaxWidth(),
        text = text,
        fontSize = pageHeaderFontSize,
        fontWeight = FontWeight.Bold,
        textAlign = TextAlign.Center,
    )
}

@Composable
fun BoldText(text: String, modifier: Modifier = Modifier) {
    Text(
        modifier = modifier.fillMaxWidth(),
        text = text,
        fontSize = normalTextSize,
        fontWeight = FontWeight.Bold,
    )
}

@Composable
fun NormalText(text: String, modifier: Modifier = Modifier) {
    Text(
        modifier = modifier.fillMaxWidth(),
        text = text,
        fontSize = normalTextSize,
    )
}

@Composable
fun NormalText(text: AnnotatedString, modifier: Modifier = Modifier) {
    Text(
        modifier = modifier.fillMaxWidth(),
        text = text,
        fontSize = normalTextSize,
    )
}

@Composable
fun BulletedItem(modifier: Modifier = Modifier, content: @Composable () -> Unit) {
    Row(modifier, verticalAlignment = Alignment.Top) {
        Box(
            Modifier
                .padding(top = smallPadding + smallPadding, end = smallPadding)
                .background(Color.Black, shape = CircleShape)
                .size(smallPadding)
        )
        content()
    }
}

@Composable
fun SubHeader1(text: String, modifier: Modifier = Modifier) {
    Text(
        modifier = modifier.fillMaxWidth(),
        text = text,
        fontSize = subHeader1FontSize,
        fontWeight = FontWeight.Bold,
        lineHeight = subHeader1FontSize.times(1.2),
    )
}

@Composable
fun SubHeader2(text: String, modifier: Modifier = Modifier) {
    Text(
        modifier = modifier.fillMaxWidth(),
        text = text,
        fontSize = subHeader2FontSize,
        fontWeight = FontWeight.Bold,
        lineHeight = subHeader2FontSize,
    )
}

@Composable
fun SubHeader3(text: String, modifier: Modifier = Modifier) {
    Text(
        modifier = modifier.fillMaxWidth(),
        text = text,
        fontSize = normalTextSize,
        fontStyle = FontStyle.Italic,
        fontWeight = FontWeight.Bold,
    )
}

@Composable
fun SubSection(title: String, text: String, modifier: Modifier = Modifier) {
    Column(modifier, verticalArrangement = Arrangement.spacedBy(smallPadding)) {
        SubHeader3(title)
        NormalText(text)
    }
}

@Composable
fun SubSection2(title: String, text: String, modifier: Modifier = Modifier) {
    Column(modifier, verticalArrangement = Arrangement.spacedBy(smallPadding)) {
        SubHeader2(title)
        NormalText(text)
    }
}

@Composable
fun SubSection2(title: String, text: AnnotatedString, modifier: Modifier = Modifier) {
    Column(modifier, verticalArrangement = Arrangement.spacedBy(smallPadding)) {
        SubHeader2(title)
        NormalText(text)
    }
}

@Composable
fun SectionSpacer(height:Dp = 8.dp, modifier: Modifier = Modifier){
    Spacer(modifier = modifier.height(height))
}

fun AnnotatedString.Builder.appendBold(text: String) {
    withStyle(style = SpanStyle(fontWeight = FontWeight.Bold)) { append(text) }
}

fun AnnotatedString.Builder.appendBoldItalic(text: String) {
    withStyle(
        style = SpanStyle(
            fontWeight = FontWeight.Bold,
            fontStyle = FontStyle.Italic,
        )
    ) { append(text) }
}

fun AnnotatedString.Builder.appendBlue(text: String) {
    withStyle(style = SpanStyle(color = Colors.PrivacyBlue)) { append(text) }
}