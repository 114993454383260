package model

import linx.linxapp.generated.resources.Res
import linx.linxapp.generated.resources.us
import org.jetbrains.compose.resources.DrawableResource

enum class CountryCodeAndFlag(
    val countryName: String,
    val flagImage: DrawableResource,
    val countryCode: Int,
) {
    US("US", Res.drawable.us, 1);

    companion object {
        fun fromCountryCode(countryCode: Int): CountryCodeAndFlag {
            return entries.firstOrNull { it.countryCode == countryCode } ?:
            throw Exception("Unhandled country code")
        }
    }
}