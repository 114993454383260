package ui.scrollbar

import androidx.compose.foundation.lazy.LazyListItemInfo
import androidx.compose.foundation.lazy.LazyListState
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.unit.Dp

const val oneHundredPercentDecimal = 1f

const val isEmpty = 0

/**
 * Enumerates the possible visibility states of an element within the user interface.
 */
enum class VisibilityState {
    /** The element is fully visible to the user.*/
    COMPLETELY_VISIBLE,

    /** Only a portion of the element is visible to the user.*/
    PARTIALLY_VISIBLE,

    /** The element is not visible to the user at all.*/
    NOT_VISIBLE
}


/**
 * Enumerates the modes of selection available for a scrollbar. Defines how a scrollbar
 * can be interacted with or if it's interactable at all.
 */
enum class ScrollbarSelectionMode {
    /**
     * Allows interaction with the full scrollbar. Users can click or drag anywhere on the scrollbar
     * to scroll the content.
     */
    Full,

    /**
     * Limits interaction to the scrollbar's thumb only. Users can only click or drag the thumb
     * (the part of the scrollbar indicating the current scroll position) to scroll the content.
     */
    Thumb,

    /**
     * Disables interaction with the scrollbar. The scrollbar may still be visible for
     * informational purposes (indicating the current scroll position), but it cannot be interacted with.
     */
    Disabled
}

/**
 * Enumerates the conditions under which scrollbar actions (such as dragging) are enabled.
 */
enum class ScrollbarSelectionActionable {
    /**
     * Indicates that the scrollbar actions are always enabled, regardless of the scrollbar's visibility.
     * Users can interact with the scrollbar at any time.
     */
    Always,

    /**
     * Indicates that scrollbar actions are only enabled when the scrollbar is visible.
     * If the scrollbar is hidden due to lack of overflow or other reasons, it cannot be interacted with.
     */
    WhenVisible,
}

/**
 * Represents the list indicators' settings, which could be enabled with specific properties or disabled.
 *
 * This sealed class allows for exhaustive when expressions in Kotlin, making it easier to handle all possible settings
 * for list indicators within a UI context. It provides two concrete implementations:
 * - [Disabled] for when indicators are not needed.
 * - [EnabledMirrored] for when indicators should be displayed, along with their customization options.
 * - [EnabledIndividualControl] for when indicators should be displayed, along with their customization options.
 */
sealed class ListIndicatorSettings {

    /**
     * Represents the state where list indicators are not shown.
     */
    data object Disabled : ListIndicatorSettings()

    /**
     * Represents the state where list indicators are enabled and can be customized but are mirrored.
     *
     * @param indicatorHeight The height of the indicator in pixels.
     * @param indicatorColor The color of the indicator.
     * @param graphicIndicator The graphic to be displayed as the indicator. This should be in the "UP" orientation.
     */
    data class EnabledMirrored(
        val indicatorHeight: Dp,
        val indicatorColor: Color,
        val graphicIndicator: @Composable (modifier: Modifier, alpha: Float) -> Unit = { _, _ -> },
    ) : ListIndicatorSettings()

    /**
     * Represents the state where list indicators are enabled and can be customized fully.
     *
     * @param upperIndicatorHeight The height of the indicator in pixels for the upper indicator.
     * @param upperIndicatorColor The color of the indicator for the upper indicator.
     * @param upperGraphicIndicator The graphic to be displayed as the indicator. This should be in the "UP" orientation.
     * @param lowerIndicatorHeight The height of the indicator in pixels for the lower indicator.
     * @param lowerIndicatorColor The color of the indicator for the lower indicator.
     * @param lowerGraphicIndicator The graphic to be displayed as the indicator. This should be in the "DOWN" orientation.
     */
    data class EnabledIndividualControl(
        val upperIndicatorHeight: Dp,
        val upperIndicatorColor: Color,
        val upperGraphicIndicator: @Composable (modifier: Modifier, alpha: Float) -> Unit = { _, _ -> },
        val lowerIndicatorHeight: Dp,
        val lowerIndicatorColor: Color,
        val lowerGraphicIndicator: @Composable (modifier: Modifier, alpha: Float) -> Unit = { _, _ -> },
    ) : ListIndicatorSettings()
}


internal fun calculateVisibilityStates(
    listState: LazyListState,
    showItemIndicator: ListIndicatorSettings,
): Pair<VisibilityState, VisibilityState> {
    val layoutInfo = listState.layoutInfo
    val totalItemCount = layoutInfo.totalItemsCount
    val visibleItems = layoutInfo.visibleItemsInfo
    val firstVisibleItemIndex = listState.firstVisibleItemIndex
    val firstItemVisibleOffset = listState.firstVisibleItemScrollOffset
    val viewportSize = layoutInfo.viewportSize.height

    if (layoutInfo.totalItemsCount == 0) {
        return Pair(VisibilityState.NOT_VISIBLE, VisibilityState.NOT_VISIBLE)
    }

    if (showItemIndicator is ListIndicatorSettings.Disabled) {
        return Pair(VisibilityState.COMPLETELY_VISIBLE, VisibilityState.COMPLETELY_VISIBLE)
    }

    // Calculate visibility for content above
    val contentAboveState = when {
        !layoutInfo.reverseLayout -> {
            if (firstVisibleItemIndex == 0 && firstItemVisibleOffset == 0) VisibilityState.COMPLETELY_VISIBLE
            else if (visibleItems.none { it.index == 0 }) VisibilityState.NOT_VISIBLE
            else VisibilityState.PARTIALLY_VISIBLE
        }

        else -> {
            determineVisibilityState(visibleItems, totalItemCount, viewportSize, listState)
        }
    }

    // Calculate visibility for content below
    val contentBelowState = when {
        !layoutInfo.reverseLayout -> {
            determineVisibilityState(visibleItems, totalItemCount, viewportSize, listState)
        }

        else -> {
            if (firstVisibleItemIndex == 0 && firstItemVisibleOffset == 0) VisibilityState.COMPLETELY_VISIBLE
            else if (visibleItems.none { it.index == 0 }) VisibilityState.NOT_VISIBLE
            else VisibilityState.PARTIALLY_VISIBLE
        }
    }

    return Pair(contentAboveState, contentBelowState)
}

private fun determineVisibilityState(
    visibleItems: List<LazyListItemInfo>,
    totalItemCount: Int,
    viewportSize: Int,
    listState: LazyListState,
): VisibilityState {
    val lastItem = visibleItems.lastOrNull()
    return if (lastItem != null && lastItem.index == totalItemCount - 1 && (lastItem.size + lastItem.offset) <= viewportSize) VisibilityState.COMPLETELY_VISIBLE
    else if (visibleItems.none { it.index == totalItemCount - 1 }) VisibilityState.NOT_VISIBLE
    else if (visibleItems.first().index == 0 && !listState.canScrollBackward) VisibilityState.COMPLETELY_VISIBLE
    else VisibilityState.COMPLETELY_VISIBLE
}

