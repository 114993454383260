package model

import linx.linxapp.generated.resources.Res
import linx.linxapp.generated.resources.american_express_svgrepo_com
import linx.linxapp.generated.resources.mastercard_light
import linx.linxapp.generated.resources.visa_color
import org.jetbrains.compose.resources.DrawableResource

enum class CardType(initialDigit: String) {
    AMEX("3"),
    VISA("4"),
    MasterCard("5"),
    Discover("6"),
    Unknown("7");
    companion object {
        fun fromString(digit: String?): CardType {
            return when (digit) {
                "3" -> AMEX
                "4" -> VISA
                "5" -> MasterCard
                "6" -> Discover
                else -> Unknown
            }
        }

        fun getDrawable(digit: String?): DrawableResource {
            val cardType = CardType.fromString(digit?.substring(0, 1))
            return when (cardType) {
                AMEX -> Res.drawable.american_express_svgrepo_com
                VISA -> Res.drawable.visa_color
                MasterCard -> Res.drawable.mastercard_light
//                Discover -> TODO()
//                Unknown -> TODO()
                else -> {
                    Res.drawable.visa_color
                }
            }
        }
    }
}